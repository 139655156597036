import React, { createContext } from "react";
import { useTranslation } from "react-i18next";

export const ProjectsContext = createContext()

export const ProjectsProvider = ({ children }) => {
    const [t, i18n] = useTranslation(["projects"])
    const projects = t("projects", { returnObjects: true });
    const maxScrollArea = window.innerHeight * 0.9;
    const numberOfProjects = Object.keys(projects).length;

    const onProjectCardScroll = (projectCardRef, setProjectScaleCard) => {
        if (projectCardRef.current) {
            const position =
                window.innerHeight -
                projectCardRef.current.getBoundingClientRect().top;
            const scrollProgress = (position * 100) / maxScrollArea;

            if (position > maxScrollArea * 0.33 && position < maxScrollArea) {
                setProjectScaleCard(linear_function(scrollProgress));
            }
        }
    };

    /* calculate element size increase/decrease percentage at each scroll */
    const linear_function = (x) => {
        /* -0.0005 = angular coefficient => (0.95-1) / (100-0) ==> 0.95-1 = element size variation / 100-0 = scroll variation */
        return window.innerWidth > 767 ? -0.0005 * x + 1 : -0.001 * x + 1;
    };

    const repeatText = (name) => {
        const elements = [];

        for (let i = 0; i < 10; i++) {
            const element = React.createElement('span', { key: i }, name)
            elements.push(element);
        }

        return elements;
    }

    const createProjectList = (projects, limit, project) => {
        const list = [];
        const projectsList = Object.keys(projects);

        if (project) {
            projectsList.sort(() => Math.random() - 0.5);

            projectsList.forEach((element) => {
                if (
                    list.length < limit &&
                    projects[element].title !== project.title
                ) {
                    list.push(element);
                }
            })
        } else {
            projectsList.forEach((element) => {
                if (list.length < limit) {
                    list.push(element);
                }
            })
        }

        return list;
    };

    return (
        <ProjectsContext.Provider value={{
            t,
            i18n,
            projects,
            linear_function,
            repeatText,
            onProjectCardScroll,
            createProjectList,
            numberOfProjects
        }}>
            {children}
        </ProjectsContext.Provider>
    )
}