import styled from "styled-components";
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#5056fd' : '#222633')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#222633' : '#f6f7f8')};
    font-size: ${({fontBig}) => (fontBig ? '20px': '16px')};
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all .2s ease-in-out; 
        background: ${({primary}) => (primary ? '#f6f7f8' : '#5056fd')};
        color: ${({dark}) => (dark ? '#f6f7f8' : '#222633')};
    }
`