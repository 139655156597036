import React, { useContext } from 'react';
import BlogPost from '../../components/BlogPost';
import { useParams } from 'react-router-dom';
import Seo from "../../components/Seo"
import ScrollToTop from "../../components/ScrollToTop";
import Footer from "../../components/Footer";
import { PostsContext } from '../../Providers/PostsContext';

const ArticlePage = () => {
    const { name } = useParams()
    const { posts } = useContext(PostsContext)
    const post = posts[name]

    return (
        <>
            <Seo seoData={{title: post.title, description: post.shortDescription, keywords: post.keywords}} />
            <ScrollToTop />
            <BlogPost postData={post} />
            <Footer />
        </>
    )
}

export default ArticlePage
