import styled from "styled-components";
import { lighten } from 'polished';

export const ProjectDetailsContainer = styled.div`
    width: 100%;
`;

export const ProjectDetailsHero = styled.div`
    width: 100%;
    height: 68vh;
    background: ${({ img }) => `url(${img})`};
    background-size: cover;
    background-position: center;

    @media (min-width: 1024px) {
        height: 78vh;
    }
`;

export const OverlayHero = styled.div`
    width: 100%;
    height: 101%;
    background: linear-gradient(
        to bottom,
        #f7f8fa00,
        #f7f8fa39 43%,
        #f7f8fa74 67%,
        #f7f8fa
    );
`;

export const ProjectDetailsContent = styled.div`
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
`;

export const ProjectTitle = styled.h1`
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;

    @media (min-width: 768px) {
        font-size: 4rem;
        text-align: left;
    }
`;

export const ProjectDetailsHeading = styled.h2`
    margin-bottom: 16px;
    font-size: 1.8rem;
    font-weight: 400;

    @media (min-width: 1024px) {
        margin-bottom: 0;
    }
`;

export const ProjectDetailsDescription = styled.p`
    font-size: 1.5rem;
    font-weight: normal;
    color: #777;
    margin-bottom: 4vh;
`;

export const ProjectDetailsParagraph = styled.p`
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

export const ProjectImageContainer = styled.figure`
    width: 100%;
    margin: 0 auto 5vh;
`;

export const ProjectImage = styled.img`
    width: 100%;
    height: 100%;
    box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.25);
`;

export const ProjectDetailsList = styled.ul`
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
`;

export const ProjectDetailsListItem = styled.li`
    margin-bottom: 0.5rem;
`;

export const ProjectDetailsOrderedList = styled.ol`
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
`;

export const ProjectDetailsCTAButton = styled.a`
    border-radius: 50px;
    background: #5056fd;
    padding: 10px 20px;
    color: #f6f7f8;
    font-size: 24px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin: 1rem 0;
    text-align: center;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${lighten(0.2, "#5056fd")};
        color: #222633;
    }
`;