import React, { useContext } from "react";
import PostCard from "../PostCard";
import { Sidebar } from "../Sidebar";
import { Navbar } from "../Navbar";
import { PostListSection } from "./PostListElements";
import FadeInAnimation from "../FadeInAnimation";
import { CommonContext } from "../../Providers/CommonContext";
import { PostsContext } from "../../Providers/PostsContext";

const PostList = () => {
    const {
        isOpen,
        toggle
    } = useContext(CommonContext)

    const {
        t,
        posts
    } = useContext(PostsContext)

    const pageTitle = t('pageTitle');

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} darkMode={true} />
            <PostListSection>
                <h1>{pageTitle}</h1>
                <ul>
                    {Object.keys(posts).map((postKey, index) => (
                        <FadeInAnimation key={index} threshold={0.5} transitionDelay={(index + 1) * 0.33} customTag="li" className="postItem">
                            <PostCard post={posts[postKey]} path={`/content/${postKey}`} />
                        </FadeInAnimation>
                    ))}
                </ul>
            </PostListSection>
        </>
    )
}

export default PostList