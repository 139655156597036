import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";

export const SocialFeedContext = createContext();

export const SocialFeedProvider = ({ children }) => {
    const [t, i18n] = useTranslation(["common"])
    const [instagramPosts, setInstagramPosts] = useState({
        'pt-BR': [],
        'en-US': []
    });
    const [requestDone, setRequestDone] = useState(false);

    const language = i18n.language;

    if (!requestDone) {
        let instagramURL = `https://graph.instagram.com/me/media?fields=id,permalink,media_url,media_type,caption&access_token=${process.env.REACT_APP_FB_INTEGRATION_TOKEN_BR}`
        fetch(instagramURL)
            .then(response => response.json())
            .then(response => setInstagramPosts(prev => ({...prev, 'pt-BR': response.data})))
            .catch(err => console.log(err));

        instagramURL = `https://graph.instagram.com/me/media?fields=id,permalink,media_url,media_type,caption&access_token=${process.env.REACT_APP_FB_INTEGRATION_TOKEN_US}`
            fetch(instagramURL)
                .then(response => response.json())
                .then(response => setInstagramPosts(prev => ({...prev, 'en-US': response.data})))
                .catch(err => console.log(err));
        
        setRequestDone(true);
    }

    return (
        <SocialFeedContext.Provider value={{
            t,
            language,
            instagramPosts
        }}>
            {children}
        </SocialFeedContext.Provider>
    )
}