import React, { useContext } from "react";
import ScrollToTop from "../../components/ScrollToTop";
import { ProjectsPageContainer } from "./projectsElements";
import { Sidebar } from "../../components/Sidebar";
import { Navbar } from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import ProjectList from "../../components/ProjectList";
import Footer from '../../components/Footer';
import FadeInAnimation from "../../components/FadeInAnimation";
import { CommonContext } from "../../Providers/CommonContext";
import { ProjectsContext } from "../../Providers/ProjectsContext";

const ProjectsPage = () => {
    const {
        isOpen,
        toggle
    } = useContext(CommonContext)

    const { t } = useContext(ProjectsContext)

    return (
        <ProjectsPageContainer>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <FadeInAnimation threshold={0.5}>
                <PageTitle title={t('projectsPage.title')} lightText={true} />
            </FadeInAnimation>
            <FadeInAnimation threshold={0}>
                <ProjectList />
            </FadeInAnimation>
            <Footer />
        </ProjectsPageContainer>
    );
};

export default ProjectsPage;
