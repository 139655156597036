import styled from "styled-components";

export const ContactUsSection = styled.section`
    width: 100%;
    min-height: 60vh;
    background-color: #222633;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .contactUsTextWrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;

        h2 {
            color: #fff;
            font-size: 44px;
            margin: 10px 0px;
            font-weight: 600;

            @media screen and (min-width: 1024px) {
                font-size: 52px;
            }
        }

        p {
            color: #fff;
            font-size: 18px;
            margin: 10px 0px;
            max-width: 630px;
        }

        a {
            font-size: 18px;
            border-radius: 8px;
            max-width: 240px;
            margin: 25px 0px;
        }
    }
`;

// export const ContactUsTextWrapper = styled.div`

// `;