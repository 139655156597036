export const homeSeo = {
    title: 'Develoci',
    description: 'Develoci Commerce Intelligence',
    keywords: 'develoci, salesforce, commerce cloud, development, ecommerce',
    imageURL: 'http://develoci.com/images/develoci_open_graph.png',
}

export const contactSeo = {
    title: 'Contact Us',
    description: 'Contact Us Page',
    keywords: 'develoci, salesforce, commerce cloud, development, ecommerce, contact ,contact us',
    imageURL: 'http://develoci.com/images/develoci_open_graph.png',
}

export const blogSeo = {
    title: 'Blog',
    description: 'Blog Page',
    keywords: 'develoci, salesforce, commerce cloud, development, ecommerce, blog',
    imageURL: 'http://develoci.com/images/develoci_open_graph.png',
}

export const aboutSeo = {
    title: 'About Us',
    description: 'About Us Page',
    keywords: 'develoci, salesforce, commerce cloud, development, ecommerce, about ,about us',
    imageURL: 'http://develoci.com/images/develoci_open_graph.png',
}

export const servicesSeo = {
    title: 'Services',
    description: 'Services Page',
    keywords: 'develoci, salesforce, commerce cloud, development, ecommerce, services , service',
    imageURL: 'http://develoci.com/images/develoci_open_graph.png',
}