import PostList from "../../components/PostList";
import { blogSeo } from "../../components/Seo/Data";
import Seo from "../../components/Seo"
import ScrollToTop from "../../components/ScrollToTop";
import Footer from "../../components/Footer";


const ArticlesListPage = () => {

    return (
        <>
            <Seo seoData={blogSeo} />
            <ScrollToTop />
            <PostList />
            <Footer />
        </>
    )
}

export default ArticlesListPage;