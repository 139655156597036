import React, { useContext } from 'react';
import { BannerButton, ButtonContainer, BannerContainer, BannerText } from './CookieConsentBannerElements';
import { CookiesContext } from '../../Providers/CookiesContext';

const CookieConsentBanner = () => {

  const {t ,showBanner, handleAccept, handleCancel  } = useContext(CookiesContext)

  if (!showBanner) {
     return null; // Don't render the banner if consent has been given
  }

  return (
    <BannerContainer>
        <BannerText>{t("cookieConsent.text")}</BannerText>
        <ButtonContainer>
          <BannerButton accept="true" onClick={handleAccept}>{t("cookieConsent.accept")}</BannerButton>
          <BannerButton onClick={handleCancel}>{t("cookieConsent.decline")}</BannerButton>
        </ButtonContainer>
    </BannerContainer>
  );
};

export default CookieConsentBanner;
