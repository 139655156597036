import { Button } from "../ButtonElement"
import { useContext } from "react"
import { ContactUsSection } from "./contactUsElements"
import { ArrowForward, ArrowRight } from "../Typewriter/TypewriterElements"
import ClientList from "../ClientList"
import FadeInAnimation from "../FadeInAnimation"
import { CommonContext } from "../../Providers/CommonContext"

const ContactUs = ({id = '', scrollNavData = ''}) => {
    const { hover, onHover, t } = useContext(CommonContext)

    return (
        <ContactUsSection id={id} data-scrollnav={scrollNavData}>
            <div className="contactUsTextWrapper">
                <FadeInAnimation threshold={0.5}>
                    <h2>
                        {t('contact.homeComponentTitle')}
                    </h2>
                </FadeInAnimation>
                <FadeInAnimation threshold={0.5} transitionDelay={0.33}>
                    <p>
                        {t('contact.homeComponentParagraph')}
                    </p>
                </FadeInAnimation>
                <FadeInAnimation threshold={0.5} transitionDelay={0.66}>
                    <Button
                        to='/contact'
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary='true'
                    >
                        {t('hero.buttonLabel')}{hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </FadeInAnimation>
            </div>
            <ClientList />
        </ContactUsSection >
    )
}

export default ContactUs