import LogoList from "../LogoList"
import Logo1 from "../../images/clientLogos/well-people-logo.svg"
import Logo2 from "../../images/clientLogos/keys-logo.svg"
import Logo3 from "../../images/clientLogos/elf-cosmetics-logo.svg"
import Logo4 from "../../images/clientLogos/loccitane-en-provence-logo.svg"
import Logo5 from "../../images/clientLogos/loccitane-au-bresil-logo.svg"
import { TrustedContainer } from "./ClientListElements"
import { useContext } from "react"
import { CommonContext } from "../../Providers/CommonContext"

const imgPathArray = [
    { path: Logo1, alt: "Well People" },
    { path: Logo2, alt: "Keys Soulcare" },
    { path: Logo3, alt: "Elf Cosmetics" },
    { path: Logo4, alt: "Loccitane En Provence" },
    { path: Logo5, alt: "Loccitane Au Bresil" },
];

const ClientList = ({ noTrusted = false, noBackground = false }) => {
    const { t } = useContext(CommonContext)

    return (
        <LogoList imgPathArray={imgPathArray} noBackground={noBackground}>
            {!noTrusted && (
                <TrustedContainer>
                    {t("clients.trustedMessage")}
                </TrustedContainer>
            )}
        </LogoList>
    );
};
export default ClientList;
