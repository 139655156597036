import React, { useContext, useEffect, useState } from "react"
import { PostCardContainer } from "./PostCardElements"
import PostImg from "../../images/logo.png"
import { FaUserCircle } from "react-icons/fa"
import { CommonContext } from "../../Providers/CommonContext"

const PostCard = ({ post, path }) => {
    let [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 800);
        };
        updateIsMobile();
        window.addEventListener('resize', updateIsMobile);
        return () => {
            window.removeEventListener('resize', updateIsMobile);
        }
    }, [])
    const { longStringsFormatter } = useContext(CommonContext)

    const formatedPostCardTitle = longStringsFormatter(post.title, 24)
    const formatedShortDescription = longStringsFormatter(post.shortDescription, 120)


    return (
        <PostCardContainer to={path} aria-label={post.ariaLabel}>
            <div className="postCardInfo">
                <h2>{formatedPostCardTitle}</h2>
                <p>
                    {formatedShortDescription}
                </p>

                <div className="authorInfo">
                    {post.authorImage ? (
                        <img src={process.env.PUBLIC_URL + post.authorImage} alt={post.authorImageAlt} />
                    ) : (
                        <FaUserCircle />
                    )}
                    <div>
                        <span className="authorName">{post.author}</span>
                        <span className="postDate">{post.postDate}</span>
                    </div>
                </div>
            </div>
            <figure>
                {post.postCardImage ? (
                    <img className="postImage" src={isMobile ? process.env.PUBLIC_URL + post.postCardImageMb : process.env.PUBLIC_URL + post.postCardImage} alt={post.postCardImageAlt} />
                ) : (
                    <img className="genericPostImage" src={PostImg} alt={post.postCardImageAlt} />
                )}
            </figure>
        </PostCardContainer>
    )
}

export default PostCard