import {
    ProjectsSectionContainer,
    ProjectsSectionContent,
    ProjectsSectionHeader,
    ProjectsSectionHeading,
    ProjectsSectionCTAButton,
    ProjectCards,
} from "./ProjectsSectionElements";
import ProjectCard from "../ProjectCard";
import FadeInAnimation from "../FadeInAnimation";
import { useContext } from "react";
import { ProjectsContext } from "../../Providers/ProjectsContext";

const ProjectsSection = ({
    lightBg,
    lightText,
    listLimit,
    project = undefined,
}) => {

    const {
        t,
        projects,
        createProjectList
    } = useContext(ProjectsContext)

    return (
        <ProjectsSectionContainer lightBg={lightBg} lightText={lightText}>
            <ProjectsSectionContent>
                <FadeInAnimation threshold={0.5}>
                    <ProjectsSectionHeader lightText={lightText}>
                        <ProjectsSectionHeading>
                            {!project ? t("projectsSection.title") : t("otherProjectsSection.title")}
                        </ProjectsSectionHeading>
                        <ProjectsSectionCTAButton href="/projects" lightText={lightText}>
                            {!project ? t("projectsSection.buttonLabel") : t("otherProjectsSection.buttonLabel")}
                        </ProjectsSectionCTAButton>
                    </ProjectsSectionHeader>
                </FadeInAnimation>

                <ProjectCards>
                    {createProjectList(projects, listLimit, project).map(
                        (item, index) => (
                            <ProjectCard
                                key={index}
                                path={item}
                                name={projects[item].title}
                                img={projects[item].heroImage}
                                page=''
                                transitionDelay={(1 + index) * 0.33}
                            />
                        )
                    )}
                </ProjectCards>
            </ProjectsSectionContent>
        </ProjectsSectionContainer>
    );
};

export default ProjectsSection;
