import styled from "styled-components";

export const ProjectsSectionContainer = styled.section`
    width: 100%;
    padding: 10vh 0;
    color: ${({lightText}) => (lightText ? '#f7f8fa':'#222633')};
    background-color: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#222633')};
`

export const ProjectsSectionContent = styled.div`
    width: 90%;
    margin: 0 auto;
    max-width: 1024px;
`

export const ProjectsSectionHeader = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: ${({lightText}) => (lightText ? '#f7f8fa':'#222633')};
    margin-bottom: 24px;
    
    @media (min-width: 768px) {
        flex-direction: row;
        gap: 0;
        padding: 0 10px;
    }
`

export const ProjectsSectionHeading = styled.h2`
    font-size: 1.8rem;
    font-weight: 600;
`

export const ProjectsSectionCTAButton = styled.a`
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    border: 1px solid ${({lightText}) => (lightText ? '#f7f8fa':'#222633')};
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:visited {
        color: ${({lightText}) => (lightText ? '#f7f8fa':'#222633')};
    }
`

export const ProjectCards = styled.ul`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }
`