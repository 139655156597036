export const renderContent = (
    object,
    Paragraph=undefined,
    Heading=undefined,
    List=undefined,
    ListItem=undefined,
    OrderedList=undefined,
    Button=undefined,
    ImageContainer=undefined,
    Image=undefined
) => {
    return object.content.map((section, index) => {
        switch (section.type) {
            case "paragraph":
                return <Paragraph key={index}>{section.text}</Paragraph>;
            case "heading":
                return <Heading key={index}>{section.text}</Heading>;
            case "list":
                return (
                    <List key={index}>
                        {section.items.map((item, idx) => (
                            <ListItem key={idx}>{item}</ListItem>
                        ))}
                    </List>
                );
            case "ordered_list":
                return (
                    <OrderedList key={index}>
                        {section.items.map((item, idx) => (
                            <ListItem key={idx}>{item}</ListItem>
                        ))}
                    </OrderedList>
                );
            case "image":
                return (
                    <ImageContainer key={index}>
                        <Image src={section.path} alt={section.alt} />
                    </ImageContainer>
                );
            case "youtube":
                const aspectRatio = (9 / 16) * 100; // Calculate aspect ratio (9:16 = 56.25%)
                return (
                    <div
                        key={index}
                        style={{
                            position: "relative",
                            paddingBottom: `${aspectRatio}%`, // Set the height based on the aspect ratio
                            height: 0,
                            maxWidth: "100%",
                        }}
                    >
                        <iframe
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                            src={`https://www.youtube.com/embed/${section.videoId}`}
                            title={section.title}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                );
            case "cta":
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                        key={index}
                    >
                        <Button href={section.url}>
                            {section.label}
                        </Button>
                    </div>
                );
            default:
                return null;
        }
    });
};
