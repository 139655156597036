import styled from 'styled-components'

export const PostListSection = styled.main`
    min-height: 70vh;
    margin-top: 10vh;
    padding: 0px 20px;

    h1 {
        font-size: 40px;
        color: #333;
        padding: 20px 0px;
        text-align: center;
        display: none;
    }

    ul {
        display: flex; 
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 25px;
        margin: 0 auto;
        list-style: none;
        padding: 20px 5px 50px 5px;
        width: 90%;

        @media screen and (min-width: 768px) {
            max-width: 1440px;
        }
    }

    .postItem {
        width: 30%;
        max-width: 320px;
        min-width: 300px;
        height: 100%;
    }
`