import React, { useContext } from "react";
import {
    FooterContainer,
    FooterWrap,
    FooterBrand,
    FooterBrandText,
    SocialLogo,
    Logo,
    SocialMedia,
    SocialMediaList,
    SocialMediaItem,
    SocialIconLink,
    SocialIcon,
    FooterLinksContainer,
    FooterLinksList,
    FooterLinkItem,
    FooterLink,
    WebSiteRights,
    WebSiteRightsText,
    CnpjText,
    CompanyName
} from "./FooterElements";
import {
    FaLinkedin,
    FaTwitter,
    FaInstagram,
    FaYoutube,
    FaFacebook,
} from "react-icons/fa";
import Develoci from "../../images/logo_footer.webp";
import { CommonContext } from "../../Providers/CommonContext";
import { ProjectsContext } from "../../Providers/ProjectsContext";

const socialIcon = {
    Linkedin: <FaLinkedin />,
    Facebook: <FaFacebook />,
    Instagram: <FaInstagram />,
    Twitter: <FaTwitter />,
    Youtube: <FaYoutube />,
};

const Footer = () => {
    const {
        t,
        toggleHome,
        footerContent
    } = useContext(CommonContext)

    const { numberOfProjects } = useContext(ProjectsContext);


    return (
        <FooterContainer>
            <FooterWrap>
                <FooterBrand>
                    <SocialLogo to="/" onClick={toggleHome}>
                        <Logo src={Develoci} alt={footerContent.accessibility.altLogo} />
                    </SocialLogo>
                    <FooterBrandText>{footerContent.shortText}</FooterBrandText>
                </FooterBrand>

                <FooterLinksContainer>
                    <FooterLinksList>
                        {footerContent.pageLinks.filter((pageLink) => {
                            return pageLink.title !== 'Projects' && pageLink.title !== 'Projetos' ? true : numberOfProjects > 0 ? true : false
                        }).map((pageLink, index) => (
                            <FooterLinkItem key={index}>
                                <FooterLink to={pageLink.path}>
                                    {pageLink.title}
                                </FooterLink>
                            </FooterLinkItem>
                        ))}
                    </FooterLinksList>
                </FooterLinksContainer>

                <SocialMedia>
                    <SocialMediaList>
                        {footerContent.socialLinks.map((socialLink, index) => (
                            <SocialMediaItem key={index}>
                                <SocialIconLink
                                    href={socialLink.link}
                                    target="_blank"
                                >
                                    <SocialIcon>
                                        {socialIcon[socialLink.media]}
                                    </SocialIcon>
                                    {socialLink.media}
                                </SocialIconLink>
                            </SocialMediaItem>
                        ))}
                    </SocialMediaList>
                </SocialMedia>
            </FooterWrap>
            <WebSiteRights>
                <WebSiteRightsText>
                    <CnpjText id='CNPJ'>
                        {t("footer.cnpj")}
                    </CnpjText>
                    <CompanyName id="company-name">
                        {t("footer.companyName")}
                    </CompanyName>
                    ©{new Date().getFullYear()} {t("footer.rights")}
                </WebSiteRightsText>
            </WebSiteRights>
        </FooterContainer>
    );
};

export default Footer;
