import React, { useContext } from "react";
import { ProjectListContainer } from "./ProjectListElements";
import ProjectCard from "../ProjectCard";
import { ProjectsContext } from "../../Providers/ProjectsContext";

const ProjectList = () => {

    const { projects } = useContext(ProjectsContext)

    return (
        <ProjectListContainer>
            {Object.keys(projects).map((project, index) => (
                <ProjectCard
                    key={index}
                    path={project}
                    name={projects[project].title}
                    img={projects[project].heroImage}
                    page='projects'
                />
            ))}
        </ProjectListContainer>
    );
};

export default ProjectList;