import React, { useEffect, useMemo, useContext, useRef, useState } from "react";
import {
    ProjectCardContainer,
    ProjectCardOverlay,
    MarqueeContainer,
    ProjectCardFadeContainer,
} from "./ProjectCardElements";
import { ProjectsContext } from "../../Providers/ProjectsContext";

const ProjectCard = ({ path, name, img, page, transitionDelay }) => {
    const projectCardRef = useRef(null);
    const [projectScaleCard, setProjectScaleCard] = useState(1);
    const {
        t,
        repeatText,
        linear_function,
        onProjectCardScroll,
        maxScrollArea,
    } = useContext(ProjectsContext);

    const projectName = useMemo(() => repeatText(name), [name, repeatText]);

    useEffect(() => {
        if (page === "projects" && projectCardRef.current) {
            const position =
                window.innerHeight -
                projectCardRef.current.getBoundingClientRect().top;
            const scrollProgress = (position * 100) / maxScrollArea;

            if (position > 0)
                setProjectScaleCard(linear_function(scrollProgress));

            window.addEventListener("scroll", () =>
                onProjectCardScroll(projectCardRef, setProjectScaleCard)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectCardRef]);

    return (
        <>
            {page !== "projects" ? (
                <ProjectCardFadeContainer
                    transitionDelay={transitionDelay}
                    threshold={0.25}
                    customTag="li"
                >
                    <ProjectCardContainer
                        img={process.env.PUBLIC_URL + img}
                        scale3d={`scale3d(${projectScaleCard}, ${projectScaleCard}, 1)`}
                        page={page}
                        ref={projectCardRef}
                    >
                        <ProjectCardOverlay
                            to={`/projects/${path}`}
                            aria-label={t("projectCard.ariaLabel") + name}
                        >
                            <MarqueeContainer>{projectName}</MarqueeContainer>
                        </ProjectCardOverlay>
                    </ProjectCardContainer>
                </ProjectCardFadeContainer>
            ) : (
                <ProjectCardContainer
                    img={process.env.PUBLIC_URL + img}
                    scale3d={`scale3d(${projectScaleCard}, ${projectScaleCard}, 1)`}
                    page={page}
                    ref={projectCardRef}
                >
                    <ProjectCardOverlay
                        to={`/projects/${path}`}
                        aria-label={t("projectCard.ariaLabel") + name}
                    >
                        <MarqueeContainer>{projectName}</MarqueeContainer>
                    </ProjectCardOverlay>
                </ProjectCardContainer>
            )}
        </>
    );
};

export default ProjectCard;
