import React from 'react';
import { Heading1, SubTitle } from './PageTitleElements';

const PageTitle = ({ title = '', lightText = false, subtitle }) => {
    return (
        <>
            <Heading1 lightText={lightText}>{title}</Heading1>
            {subtitle && <SubTitle lightText={lightText}>{subtitle}</SubTitle>}
        </>
    )
};

export default PageTitle;
