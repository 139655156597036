import { useContext } from "react"
import { Button } from "../ButtonElement";
import PostCard from "../PostCard";
import { ArrowForward, ArrowRight } from "../Typewriter/TypewriterElements";
import { BlogListSection, BlogTitleWrapper } from "./BlogListElements";
import FadeInAnimation from "../FadeInAnimation";
import { CommonContext } from "../../Providers/CommonContext";
import { PostsContext } from "../../Providers/PostsContext";

const BlogList = ({id = '', scrollNavData = ''}) => {
    const {
        t,
        hover,
        onHover,
    } = useContext(CommonContext)
    const {
        posts,
        lastThreePosts
    } = useContext(PostsContext)

    return (
        <BlogListSection id={id} data-scrollnav={scrollNavData}>
            <div className="blogListWrapper">
                <BlogTitleWrapper threshold={0.5}>
                    <h3 className="blogListTitle">{t("blog.blogListTitle")}</h3>
                    <Button
                        to="/blog"
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary="true"
                    >
                        {t("blog.blogListBtnLabel")}{" "}
                        {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </BlogTitleWrapper>
                <ul>
                    {lastThreePosts.map((postkey, index) => (
                        <FadeInAnimation
                            key={index}
                            threshold={0.5}
                            transitionDelay={(index + 1) * 0.33}
                            customTag="li"
                            className="postItem"
                        >
                            <PostCard
                                post={posts[postkey]}
                                path={`/content/${postkey}`}
                            />
                        </FadeInAnimation>
                    ))}
                </ul>
            </div>
        </BlogListSection>
    );
};

export default BlogList;
