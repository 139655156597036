import styled from "styled-components"
import { Link as LinkR } from 'react-router-dom';

export const PostCardContainer = styled(LinkR)`
    border-radius: 8px;
    background-color: #fff;
    color: #333;
    transition: 0.1s;
    box-shadow: 0px 0px 6px -3px #222633;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    text-decoration: none;
    cursor: pointer;

    :hover {
        box-shadow: 0px 0px 9px 0px #5056fd;
    }

    figure {
        width: 100%;
        background-color: #5056fd;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 8px 0px 0px;
        min-height: 175px;
        

        .postImage {
            width: 100%;
            height: 100%;
            border-radius: 8px 8px 0px 0px;
        }

        .genericPostImage {
            width: 90%;
        }
    }

    .postCardInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 25px;
        /* height: 250px; */

        h2 {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 19px;
            margin: 18px 0px 10px 0;
        }

        p {
            font-family: Arial, Helvetica, sans-serif;
            margin-bottom: 30px;
            font-size: 15px;
            min-height: 85px;
        }

        .authorInfo {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 30px;

            svg {
                width: 50px;
                height: 50px;
            }

            img {
                border-radius: 50%;
                width: 50px;
                height: 50px;
            }

            div {
                display: flex;
                flex-direction: column;
                gap: 2px;

                span {
                    font-family: Arial, Helvetica, sans-serif;
                }

                .authorName {
                    font-weight: 600;
                }

                .postDate {
                    font-size: 12px;
                }
            }
        }
        .imageProduct {   
            width: 400px;
            height: 400px;     
            img {
                width: 100%;
                height: 100%;
            }
        }
    }    
`

export const KnowMoreWrapper = styled.div`
    width: 100%;
    margin-top: 15px;
    text-align: center;
`