import styled from "styled-components";

export const BannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f6f7f8;
  padding: 10px;
  z-index: 9999;

  @media screen and (min-width: 767px) {
    display: flex;
    flex-direction: column;
    padding: 0.5% 26.5%;
  }
`;

export const BannerText = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: #222633;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    margin-top: 0px;
  }

  @media screen and (min-width: 767px) {
    padding-right: 10px;
    margin-top: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  grid-column-gap: 10px;

  @media screen and (max-width: 480px) {
    justify-content: center;
  }

  @media screen and (min-width: 767px) {
    max-width: 780px;
  }
`;

export const BannerButton = styled.button`
    border-radius: 50px;
    background: ${({ accept }) => (accept ? '#5056fd' : '#222633')};
    white-space: nowrap;
    padding: 12px 30px;
    color: ${({ dark }) => (dark ? '#222633' : '#f6f7f8')};
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in-out;
    text-decoration: none;

    @media screen and (max-width: 480px) {
        font-size: 12px;
        padding: 8px 30px;
    }
`;