import React, { useMemo, useContext } from 'react';

import {
  BlogPostContainer,
  BlogPostTitle,
  BlogPostDescription,
  BlogPostHeading,
  BlogPostParagraph,
  BlogPostList,
  BlogPostOrderedList,
  BlogPostListItem,
  BlogPostCTAButton
} from './BlogPostElements';
import { FaUserCircle } from "react-icons/fa"

import { Sidebar } from '../Sidebar';
import { Navbar } from '../Navbar';
import { renderContent } from '../../utils/renderContent';

import ContactUs from "../ContactUs";
import BlogList from '../BlogList';
import { CommonContext } from '../../Providers/CommonContext';

const BlogPost = ({ postData }) => {
  const {
    toggle,
    isOpen
  } = useContext(CommonContext)

  const postContent = useMemo(
    () =>
      renderContent(
        postData,
        BlogPostParagraph,
        BlogPostHeading,
        BlogPostList,
        BlogPostListItem,
        BlogPostOrderedList,
        BlogPostCTAButton
      ),
    [postData]
  );

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} darkMode={true} />
      <BlogPostContainer className="blog-post">
        <BlogPostTitle>{postData.headline}</BlogPostTitle>

        <div className="authorInfo">
            {postData.authorImage ? (
                <img src={process.env.PUBLIC_URL + postData.authorImage} alt={postData.authorImageAlt} />
            ) : (
                <FaUserCircle />
            )}
            <div>
                <span className="authorName">{postData.author}</span>
                <span className="postDate">{postData.postDate}</span>
            </div>
        </div>
        
          {postData.imageProduct ? (
            <div className="imageProduct">
            <img src={process.env.PUBLIC_URL + postData.imageProduct} alt={postData.imageProductAlt} />
          </div>) : ('')}
        <BlogPostDescription>{postData.description}</BlogPostDescription>
        {postContent}
      </BlogPostContainer>

      <BlogList />

      <ContactUs />
    </>
  );
};


export default BlogPost;