import styled from "styled-components";
import { Link } from "react-router-dom";
import FadeInAnimation from "../FadeInAnimation";

export const ProjectCardFadeContainer = styled(FadeInAnimation)`
    width: 48%;
    @media (max-width: 768px) {
        width: 100%;
    }
`

export const ProjectCardContainer = styled.div`
    position: ${({page}) => page === 'projects' ? 'sticky' : 'static'};
    top: 10vh;
    width: ${({page}) => page === 'projects' ? '95%' : '100%'};
    max-width: 1280px;
    height: 40vh;
    margin: ${({page}) => page === 'projects' ? '36px auto' : '8px auto'};
    border-radius: 16px;
    background-image: ${({ img }) => `url(${img})`};
    background-size: cover;
    will-change: transform;
    transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) ${({scale3d}) => scale3d};
    transform-style: preserve-3d;
    transition: transform 0.5s;
    box-shadow: 0px 5px 11px 0px rgba(0,0,0,0.25);
    overflow: hidden;

    @media (min-width: 768px) {
        width: ${({page}) => page === 'projects' ? '95%' : '100%'};
        height: ${({page}) => page === 'projects' ? '64vh' : '40vh'};
    }

    @media (min-width: 1440px) {
        margin: ${({page}) => page === 'projects' ? '44px auto' : '8px auto'};
    }
`

export const ProjectCardOverlay = styled(Link)`
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    color: #f7f8fa;
    font-weight: 500;
    font-size: 32px;
    text-decoration: none;
    white-space: nowrap;
    transition-duration: 0.4s;
    background-color: #00000050;

    span {
        display: flex;
        align-items: center;
        transition-duration: 0.4s;
    }

    span:not(:last-child)::after {
        content: "";
        display: block;
        width: 48px;
        height: 1px;
        margin: 10px;
        background-color: #f7f8fa;
    }

    @media (min-width: 1024px) {
        background-color: transparent;

        &:hover {
            cursor: pointer;
            background-color: #00000050;
        }

        span {
            opacity: 0;
        }

        &:hover span {
            opacity: 1;
        }
    }
`

export const MarqueeContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    animation: marquee 25s linear;

    @keyframes marquee {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
`