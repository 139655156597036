import React, { useContext } from "react";
import ScrollToTop from "../../components/ScrollToTop";
import { ProjectPageContainer } from "./projectsElements";
import { Sidebar } from "../../components/Sidebar";
import { Navbar } from "../../components/Navbar";
import ProjectDetails from "../../components/ProjectDetails";
import Footer from '../../components/Footer';
import { useParams } from "react-router-dom";
import { CommonContext } from "../../Providers/CommonContext";

const ProjectPage = () => {
    const { name } = useParams();
    const { isOpen, toggle } = useContext(CommonContext)

    return (
        <ProjectPageContainer>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} darkMode={true} />
            <ProjectDetails projectName={name} />
            <Footer />
        </ProjectPageContainer>
    );
};

export default ProjectPage;