import React, { lazy, Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

// Articles
import ArticlesListPage from './pages/articles/articleListPage';
import ArticlePage from './pages/articles/articlePage';

// Projects
import ProjectsPage from './pages/projects/projectsPage';
import ProjectPage from './pages/projects/projectPage';

// Components
import CookieConsentBanner from './components/CookieConsentBanner';

// Others
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import { LoadingSkeletonContainer } from './pages/loading/loadingSkeletonElements';

// Pages
const Home = lazy(() => import('./pages'));
const ContactPage = lazy(() => import('./pages/contact'));
const AboutUsPage = lazy(() => import('./pages/aboutUs/aboutUs'));
const ServicePage = lazy(() => import('./pages/Services/servicesPage'));
const BusinessContact = lazy(() => import('./pages/businessContact/businessContact'))

function App() {

  // GTM
  useEffect(() => {
    const americaGTM = 'GTM-5KM965N'
    const brazilGTM = 'GTM-5372W35'

    const domain = window.location.hostname
    // eslint-disable-next-line no-useless-escape
    const isBrazil = new RegExp('^.*\.com\.br$').test(domain)

    const tagManagerArgs = {
      gtmId: isBrazil ? brazilGTM : americaGTM
    }

    TagManager.initialize(tagManagerArgs)
  })

  return (
    <Router>
      <CookieConsentBanner />
      <Suspense fallback={
        <LoadingSkeletonContainer>
          <h2 style={{ marginBottom: '10px' }}>
            <Skeleton height={50} width={150} baseColor='#d2d7ff' highlightColor='#5056fd' />
          </h2>
          <p>
            <Skeleton height={20} width={450} count={3} baseColor='#d2d7ff' highlightColor='#5056fd' />
          </p>
        </LoadingSkeletonContainer>
      }>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/contact" exact element={<ContactPage />} />
          <Route path="/blog" exact element={<ArticlesListPage />} />
          <Route path="/content/:name" exact element={<ArticlePage />} />
          <Route path="/projects" exact element={<ProjectsPage />} />
          <Route path="/projects/:name" exact element={<ProjectPage />} />
          <Route path="/about" exact element={<AboutUsPage />} />
          <Route path="/services" exact element={<ServicePage />} />
          <Route path="/landing" exact element={<BusinessContact />} />
          <Route path="*" element={<Navigate to='/' />} />
        </Routes>
      </Suspense>
      {/* </LayoutGroup> */}
    </Router>
  );
}

export default App;
