import { LogoWrapper, LogosMaster, EmblaCarousel, EmblaContainer, EmblaSlide, LogoSection } from "./LogoListElements";

import { useContext, useMemo } from "react";
import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import FadeInAnimation from "../FadeInAnimation";
import { CommonContext } from "../../Providers/CommonContext";
import { useLocation } from "react-router-dom"


const LogoList = ({ imgPathArray, noBackground, children }) => {
    const [emblaRef] = useEmblaCarousel({ loop: true, duration: 15, breakpoints: { '(max-width: 768px)': { loop: true, align: 'start' } } }, [Autoplay()])
    const { createLogos } = useContext(CommonContext)
    const logos = useMemo(() => createLogos(imgPathArray), [imgPathArray, createLogos])
    const currentPage = useLocation()

    const isBusinessContactPage = currentPage.pathname === '/landing' ? true : false

    return (
        <LogoSection noBackground={noBackground} isBusinessContactPage={isBusinessContactPage}>
            <FadeInAnimation threshold={1} transitionDelay={0.5}>
                <LogoWrapper>
                    {children}
                    {logos.length < 6
                        ?
                        <LogosMaster qty={logos.length}>{logos}</LogosMaster>
                        :
                        <EmblaCarousel qty={logos.length} ref={emblaRef}>
                            <EmblaContainer>
                                {logos.map(
                                    (logo, index) => <EmblaSlide key={index}>{logo}</EmblaSlide>
                                )
                                }
                            </EmblaContainer>
                        </EmblaCarousel>
                    }
                </LogoWrapper>
            </FadeInAnimation>
        </LogoSection>
    )
}

export default LogoList;