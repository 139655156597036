import { createContext, useState } from "react";
import { toast } from "react-toastify";
import { validateEmail } from "../../utils/fieldValidation";
import { useTranslation } from "react-i18next";
import { useToast } from "../../hooks/toast";
import { gtag } from "ga-gtag";

export const ContactFormContext = createContext()

export const ContactFormProvider = ({ children }) => {
    const [t, i18n] = useTranslation(["common"]);
    const contactInfo = t(`contact`, { returnObjects: true })
    const businessContatInfo = t(`businessContact`, { returnObjects: true })
    const { toastLoading, toastUpdate } = useToast();
    const [emailError, setEmailError] = useState('')
    const [isVerified, setVerified] = useState(false)
    const [formData, setFormData] = useState({
        name: "",
        surname: "",
        email: "",
        phoneNumber: "",
        platform: "",
        service: "",
        projectDescription: ""
    });

    const [businessFormData, setBusinessFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        companyIncome: "",
        site: "",
        cnpj: ""
    });

    const isValidEmail = (email) => {
        const isValid = validateEmail(email) === true && email.includes('.com')
        isValid ? setEmailError('') : setEmailError(t('contact.emailError'))
        return isValid
    }

    const verifyCallback = () => {
        setVerified(true)
    }

    function gtag_report_conversion (url) {

        const callback = function () {
            if (typeof(url) != 'undefined') {
              window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11101225590/QxADCL3d0egYEPaEva0p',
            'event_callback': callback
        });

        return false;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const { name, surname, email, phoneNumber, platform, service, projectDescription } = formData
        const nonValidService = contactInfo.serviceOptions[0]
        const nonValidPlatform = contactInfo.platformOptions[0]
        const emailIsValid = isValidEmail(email)

        const isMissingField = Object.keys(formData).filter((key) => {
            if (formData[key] === "") {
                return true
            } else if (key === 'platform' && formData[key] === nonValidPlatform) {
                return true
            } else if (key === 'service' && formData[key] === nonValidService) {
                return true
            }
            return false
        })

        if (isMissingField.length > 0) {

            const missingFieldsMessage = isMissingField.length > 1
                ? `the fields ${isMissingField.join(" and ")} are missing`
                : `the field ${isMissingField.join("")} is missing`;

            toast.error(missingFieldsMessage, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        if (emailIsValid === false) {

            toast.error(emailError, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            return
        }
        if (!isVerified) return;

        toastLoading()

        const params = {
            from_name: name,
            from_surname: surname,
            from_email: email,
            client_phone: phoneNumber,
            selected_patform: platform,
            selected_service: service,
            message: projectDescription,
            autoReply: t('contact.autoReply')
        }
        try {
            const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID_EN_US,
                    user_id: process.env.REACT_APP_EMAILJS_USER_ID,
                    template_params: params
                }),
            });
            gtag_report_conversion();
            if (window.dataLayer) {
                window.dataLayer.push({'event': 'lead'})
            }
            setTimeout(() => toastUpdate(response), 1000)
        } catch (error) {
            console.log(error)
        }
    }

    const handleBusinessFormSubmit = async (e) => {
        e.preventDefault()

        const { fullName, email, phoneNumber, companyIncome, site, cnpj } = businessFormData
        const nonValidOption = businessContatInfo.companyIncomeOptions[0]
        const emailIsValid = isValidEmail(email)
        const isMissingField = Object.keys(businessFormData).filter((key) => {
            if (businessFormData[key] === "") {
                return true
            } else if (key === 'companyIncome' && businessFormData[key] === nonValidOption) {
                return true
            }
            return false
        })
        console.log('Ainda na funcao')
        if (isMissingField.length > 0) {
            console.log('entrei no if que verifica se tem campo faltando')
            const missingFieldsMessage = isMissingField.length > 1
                ? `the fields ${isMissingField.join(" and ")} are missing`
                : `the field ${isMissingField.join("")} is missing`;

            toast.error(missingFieldsMessage, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        if (emailIsValid === false) {
            console.log(`Opa deu errado a validacao do email deu como ${emailIsValid}`)
            toast.error(emailError, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            return
        }
        
        toastLoading()

        const params = {
            from_fullName: fullName,
            from_email: email,
            client_phone: phoneNumber,
            selected_companyIncome: companyIncome,
            client_site: site,
            client_cnpj: cnpj,
            autoReply: t('contact.autoReply')
        }
        try {
            const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    service_id: process.env.REACT_APP_EMAILJS_BUSINESS_SERVICE_ID,
                    template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID_PT_BR,
                    user_id: process.env.REACT_APP_EMAILJS_USER_ID,
                    template_params: params
                }),
            });
            gtag_report_conversion();
            if (window.dataLayer) {
                window.dataLayer.push({'event': 'lead'})
            }
            setTimeout(() => toastUpdate(response), 1000)
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <ContactFormContext.Provider
            value={{
                t,
                i18n,
                handleSubmit,
                formData,
                setFormData,
                verifyCallback,
                isVerified,
                isValidEmail,
                contactInfo,
                businessFormData,
                setBusinessFormData,
                handleBusinessFormSubmit
            }}>
            {children}
        </ContactFormContext.Provider>
    )
}
