import { useContext, useMemo } from "react";
import {
    ProjectDetailsContainer,
    ProjectDetailsHero,
    OverlayHero,
    ProjectTitle,
    ProjectDetailsDescription,
    ProjectDetailsContent,
    ProjectDetailsHeading,
    ProjectDetailsParagraph,
    ProjectImageContainer,
    ProjectImage,
    ProjectDetailsList,
    ProjectDetailsListItem,
    ProjectDetailsOrderedList,
    ProjectDetailsCTAButton
} from "./ProjectDetailsElements";
import ProjectsSection from "../ProjectsSection";
import { renderContent } from "../../utils/renderContent";
import FadeInAnimation from "../FadeInAnimation";

import ContactUs from "../ContactUs";
import { ProjectsContext } from "../../Providers/ProjectsContext";

const ProjectDetails = ({ projectName }) => {
    const { projects } = useContext(ProjectsContext)
    const project = projects[projectName];

    const projectContent = useMemo(
        () =>
            renderContent(
                project,
                ProjectDetailsParagraph,
                ProjectDetailsHeading,
                ProjectDetailsList,
                ProjectDetailsListItem,
                ProjectDetailsOrderedList,
                ProjectDetailsCTAButton,
                ProjectImageContainer,
                ProjectImage
            ),
        [project]
    );

    return (
        <ProjectDetailsContainer>
            <FadeInAnimation threshold={0.5}>
                <ProjectDetailsHero img={process.env.PUBLIC_URL + project.heroImage}>
                    <OverlayHero />
                </ProjectDetailsHero>
            </FadeInAnimation>

            <ProjectDetailsContent>
                <FadeInAnimation threshold={0.5}>
                    <ProjectTitle>{project.title}</ProjectTitle>
                </FadeInAnimation>
                <FadeInAnimation threshold={0.5}>
                    <ProjectDetailsDescription>
                        {project.description}
                    </ProjectDetailsDescription>
                </FadeInAnimation>
                <FadeInAnimation threshold={0}>
                    {projectContent}
                </FadeInAnimation>
            </ProjectDetailsContent>

            <FadeInAnimation threshold={0.5}>
                <ProjectsSection lightBg={true} lightText={false} listLimit={2} project={project} />
            </FadeInAnimation>

            <ContactUs />

        </ProjectDetailsContainer>
    );
};

export default ProjectDetails;
