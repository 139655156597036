import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';


export const useToast = () => {
    const [t] = useTranslation();
    const toastId = useRef(null)

    return {
        toastLoading: () => {
            toastId.current = toast.loading(t("contact.sendingRequest"), {
                position: "bottom-center",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            })
        },
        toastUpdate: (response) => {
            toast.update(toastId.current, {
                render: response.ok ? t("contact.sucessRequest") : t("contact.failedRequest"),
                type: response.ok ? "success" : "error",
                autoClose: 5000,
                isLoading: false
            })  
        }
    }
}