import { createContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export const CookiesContext = createContext()

export const CookiesProvider = ({ children }) => {
    const [t] = useTranslation()
    const [cookies, setCookie] = useCookies(['cookieConsent']);
    const [showBanner, setShowBanner] = useState(!cookies.cookieConsent);

    const handleAccept = () => {
        setCookie('cookieConsent', true, { path: '/' });
        setShowBanner(false);
    };

    const handleCancel = () => {
        setShowBanner(false);
    };

    return (
        <CookiesContext.Provider value={{
            t,
            cookies,
            setCookie,
            showBanner,
            setShowBanner,
            handleAccept,
            handleCancel
        }}>
            {children}
        </CookiesContext.Provider>
    )
}