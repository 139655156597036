import styled from 'styled-components';
import { lighten } from 'polished';

export const BlogPostContainer = styled.div`
  max-width: 800px;
  margin: 120px auto;
  padding: 1rem;
  font-family: 'Arial', sans-serif;
  color: #333;

  .authorInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    border-top: 1px dashed #cecece;
    border-bottom: 1px dashed #cecece;
    padding: 1rem 0;
    margin: 2rem 0;

    svg {
        width: 50px;
        height: 50px;
    }

    img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }

    div {
        display: flex;
        flex-direction: column;
        gap: 2px;

        span {
            font-family: Arial, Helvetica, sans-serif;
        }

        .authorName {
            font-weight: 600;
        }

        .postDate {
            font-size: 12px;
        }
    }
  }
  .imageProduct {        
    width: 400px;
    height: 400px;     
    img {
        width: 100%;
        height: 100%;
    }
  }
`;

export const BlogPostTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const BlogPostDescription = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  margin: 3rem 0;
  color: #777;
`;

export const BlogPostHeading = styled.h3`
  font-size: 1.8rem;
  font-weight: 400;
  margin: 2rem 0 1rem;
`;

export const BlogPostParagraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const BlogPostCTAButton = styled.a`
  border-radius: 50px;
  background: #5056fd;
  padding: 10px 20px;
  color: #f6f7f8;
  font-size: 24px;
  border: none;
  cursor: pointer;
  transition: all .2s ease-in-out;
  text-decoration: none;
  margin: 1rem 0;
  text-align: center;

  &:hover {
      transition: all .2s ease-in-out;
      background: ${lighten(0.2, '#5056fd')};
      color: #222633;
  }
`;

BlogPostCTAButton.defaultProps = {
  target: '_blank', 
  rel: 'noopener noreferrer'
};

export const BlogPostList = styled.ul`
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
`;

export const BlogPostOrderedList = styled.ol`
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
`;

export const BlogPostListItem = styled.li`
  margin-bottom: 0.5rem;
`;