import { useCallback } from 'react'

export const useScrollLock = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const html = document.documentElement
    const { body } = document

    const lockScroll = useCallback(() => {
        const scrollBarWidth = window.innerWidth - html.clientWidth
        const bodyPaddingRight =
            parseInt(window.getComputedStyle(body).getPropertyValue('padding-right')) || 0

        html.style.position = 'relative'
        html.style.overflow = 'hidden'
        html.style.touchAction = 'none'
        body.style.position = 'relative'
        body.style.overflow = 'hidden'
        body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`
        body.style.touchAction = 'none'

    }, [body, html.clientWidth, html.style])

    const unlockScroll = useCallback(() => {
        html.style.position = ''
        html.style.overflow = ''
        html.style.touchAction = ''
        body.style.position = ''
        body.style.overflow = ''
        body.style.paddingRight = ''
        body.style.touchAction = ''
    }, [body.style, html.style])

    return {
        lockScroll,
        unlockScroll
    }
}
