import styled from "styled-components";

export const ScrollNavWrapper = styled.div`
  background: ${({ scrollNav, darkMode }) => (scrollNav || darkMode ? '#13151c' : 'transparent')};
  display: ${({ scrollNav, darkMode }) =>
    scrollNav || darkMode ? "flex" : "none"};
  position: sticky;
  top: 80px;
  z-index: 10;
  margin-top: -10px;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;

  @media screen and (max-width: 960px) {
    display: flex;
    transition:  0.8s all ease;
  }

  @media screen and (max-width: 1440px) {
    height: 5px;
  }
`;

export const ScrollNavWrapperInner = styled.div`
  height: 100%;
  align-items: center;
  width: 90%;
  display: flex;
  justify-content: space-between;
  font-family: "Chillax", "Montserrat", sans-serif;
  color: white;
  max-width: 1300px;
`;

export const ScrollNavLink = styled.a`
  text-decoration: none;
  text-transform: capitalize;
  color: white;
  padding: 5px 10px;
  text-wrap: nowrap;
  position: relative;
  flex: 1;
  text-align: center;
  display: ${window.innerWidth <= 1440 ? 'none' : 'block'}
`;
