import styled from "styled-components";

export const ProjectListContainer = styled.ul`
    position: relative;
    width: 100%;
    margin: 0 0 20vh;

    @media (min-width: 768px) {
        margin: 10vh 0;
    }
`