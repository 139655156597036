import React, { useContext, useEffect, useState } from "react";
import {
  ScrollNavWrapper,
  ScrollNavWrapperInner,
  ScrollNavLink,
} from "./ScrollNavElements";
import { CommonContext } from "../../Providers/CommonContext";

export const ScrollNav = ({ darkMode }) => {
  const { scrollNav } = useContext(CommonContext);
  const sections = document.querySelectorAll("[data-scrollnav]");
  const [activeSection, setActiveSection] = useState({ id: "", percentage: 0 });

  useEffect(() => {
    const handleScroll = () => {
      let scrollPosition = window.scrollY;
      let newId = "";
      let percentage = 0;

      if (window.scrollY > 0) {
        scrollPosition += 120;
      }

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        const sectionBottom = sectionTop + sectionHeight;

        if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
          const sectionScrolled = scrollPosition - sectionTop;
          percentage = (sectionScrolled / sectionHeight) * 100;
          newId = section.id;
          break;
        }
      }

      setActiveSection({
        id: newId,
        percentage: percentage.toFixed(2),
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections, setActiveSection]);

  return (
    <ScrollNavWrapper scrollNav={scrollNav} darkMode={darkMode}>
      {window.innerWidth >= 1440 ? (
        <ScrollNavWrapperInner>
          {Array.from(sections).map(
            (section, index) =>
              section.id &&
              section.getAttribute("data-scrollnav") && (
                <ScrollNavLink key={index} href={`#${section.id}`}>
                  <div className="content">
                    {section.getAttribute("data-scrollnav")}
                  </div>
                  <div
                    className="background"
                    style={{
                      height: "100%",
                      width: `${activeSection.percentage}%`,
                      backgroundColor:
                        section.id === activeSection.id
                          ? "#5056fd"
                          : "transparent",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "-1",
                    }}
                  />
                </ScrollNavLink>
              )
          )}
        </ScrollNavWrapperInner>
      ) : (
        <div
          className="background"
          style={{
            height: "5px",
            width: `${(
              (window.scrollY /
                (document.documentElement.scrollHeight - window.innerHeight)) *
              100
            ).toFixed(2)}%`,
            backgroundColor: "#5056fd",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "-1",
          }}
        />
      )}
    </ScrollNavWrapper>
  );
};
