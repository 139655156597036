import styled from "styled-components";

export const LoadingSkeletonContainer = styled.div`
    background: linear-gradient(-45deg,#d2d7ff,#6e79ff,#5056fd,#222633);
    background-size: 400% 400%;
    -webkit-animation: gradient 15s ease infinite;
    animation: gradient 15s ease infinite;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`