import styled from "styled-components";
import FadeInAnimation from "../FadeInAnimation";

export const BlogListSection = styled.section`
    min-height: 70vh;
    background-color: #f9f9f9;

    .blogListWrapper {
        @media screen and (min-width: 1024px) {
            width: fit-content;
            min-width: 55%;
            margin: 0 auto;
        }

        ul {
            display: flex;
            overflow-y: hidden;
            width: 100%;
            max-width: fit-content;
            gap: 40px;
            margin: 20px auto;
            list-style: none;
            padding: 20px 10px 20px 10px;

            @media screen and (min-width: 1195px) {
                align-items: center;
                justify-content: center;
            }

            .postItem {
                width: 30%;
                max-width: 320px;
                min-width: 300px;
                height: 100%;
            }

            ::-webkit-scrollbar {
                height: 8px;
            }

            ::-webkit-scrollbar-track {
                border-radius: 16px;
                background: #d9d9d9;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 16px;
                background: #5056fd;
            }
        }
    }
`;

export const BlogTitleWrapper = styled(FadeInAnimation)`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    min-height: 15vh;
    padding: 0px 15px;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
    }

    .blogListTitle {
        font-size: 26px;
        font-weight: 500;

        @media screen and (min-width: 1024px) {
            font-size: 32px;
        }
    }

    a {
        max-width: 240px;
        border-radius: 8px;
    }
`;
