import { CommonInfoProvider } from "./CommonContext"
import { ContactFormProvider } from "./ContactFormContext"
import { CookiesProvider } from "./CookiesContext"
import { PostsProvider } from "./PostsContext"
import { ProjectsProvider } from "./ProjectsContext"
import { SocialFeedProvider } from "./SocialFeedContext"

const Providers = ({ children }) => {
    return (
        <CommonInfoProvider>
            <ProjectsProvider>
                <PostsProvider>
                    <ContactFormProvider>
                        <CookiesProvider>
                            <SocialFeedProvider>
                                {children}
                            </SocialFeedProvider>
                        </CookiesProvider>
                    </ContactFormProvider>
                </PostsProvider>
            </ProjectsProvider>
        </CommonInfoProvider>
    )
}

export default Providers