import styled from "styled-components";

export const ProjectsPageContainer = styled.div`
    width: 100%;
    background: linear-gradient(-45deg, #d2d7ff, #6e79ff, #5056fd, #222633);
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
`

export const ProjectPageContainer = styled.div`
    background-color: #f9f9f9;
`