import { createContext } from "react";
import { useTranslation } from "react-i18next";

export const PostsContext = createContext()

export const PostsProvider = ({ children }) => {
    const [t, i18n] = useTranslation(["posts"])
    const posts = t("posts", { returnObjects: true, ns: "posts" });
    const postKeys = Object.keys(posts)
    console.log(postKeys);
    const lastThreePosts = postKeys.slice(0,3)

    return (
        <PostsContext.Provider value={{
            t,
            i18n,
            posts,
            postKeys,
            lastThreePosts
        }}>
            {children}
        </PostsContext.Provider>
    )
}