import React, { useEffect, useState, useContext } from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavBtnLink,
  Logo,
  Flag,
  NavLinksR,
} from "./NavBarElements";
import develoci from "../../images/logo.webp";
import ReactCountryFlag from "react-country-flag";
import isMobileOrTablet from "../../utils/isMobile";
import { CommonContext } from "../../Providers/CommonContext";
import { ProjectsContext } from "../../Providers/ProjectsContext";
import { ScrollNav } from "../ScrollNav";
import { useLocation } from "react-router-dom";

export const Navbar = ({ toggle, darkMode = false }) => {
  const { t, language, changeLanguage, toggleHome, scrollNav } =
    useContext(CommonContext);
  const [isMT, setIsMT] = useState(false);

  const { numberOfProjects } = useContext(ProjectsContext);

  useEffect(() => {
    setIsMT(isMobileOrTablet(946));
  }, []);

  const currentPage = useLocation()

  const isBusinessContactPage = currentPage.pathname === '/landing' ? true : false

  return (
    <>
      {isBusinessContactPage ? (
        <>
          <Nav scrollNav={scrollNav} darkMode={darkMode}>
            <NavbarContainer style={isBusinessContactPage && {display: "flex", alignItems: "center", justifyContent: "center"}}>
              <NavLogo to="/" onClick={toggleHome}>
                <Logo src={develoci} alt={t("menu.accessibility.navLogoAlt")} />
              </NavLogo>
            </NavbarContainer>
          </Nav>
          <ScrollNav darkMode={darkMode} />
        </>
      ) : (
        <>
          <Nav scrollNav={scrollNav} darkMode={darkMode}>
            <NavbarContainer>
              <NavLogo to="/" onClick={toggleHome}>
                <Logo src={develoci} alt={t("menu.accessibility.navLogoAlt")} />
              </NavLogo>
              <MobileIcon
                onClick={toggle}
                aria-label={t("menu.accessibility.menuBurguerAriaLabel")}
              >
                <FaBars />
              </MobileIcon>
              <NavMenu aria-hidden={isMT ? true : false}>
                <NavItem>
                  <NavLinksR to="/about">{t("menu.about")}</NavLinksR>
                </NavItem>
                <NavItem>
                  <NavLinksR to="/services">{t("menu.services")}</NavLinksR>
                </NavItem>
                {numberOfProjects > 0 && (
                  <NavItem>
                    <NavLinksR to="/projects">{t("menu.projects")}</NavLinksR>
                  </NavItem>
                )}
                <NavItem>
                  <NavLinksR to="/blog">{t("menu.articles")}</NavLinksR>
                </NavItem>
                <NavItem>
                  <Flag
                    onClick={changeLanguage}
                    aria-label={t("menu.accessibility.flagBtnAriaLabel")}
                  >
                    <ReactCountryFlag
                      countryCode={language === "en-US" ? "BR" : "US"}
                      svg
                      alt=""
                    />
                    <span>{language === "en-US" ? "pt-BR" : "en-US"}</span>
                  </Flag>
                </NavItem>
              </NavMenu>
              <NavBtnLink to="/contact" aria-hidden={isMT ? true : false}>
                {t("menu.contact")}
              </NavBtnLink>
            </NavbarContainer>
          </Nav>
          <ScrollNav darkMode={darkMode} />
        </>
      )}
      
    </>
  );
};
